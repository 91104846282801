.gallery {
  width: 100%;
  max-width: 1200px;
  padding: 0 10px;
  margin: 40px auto;
}

.gallery hr {
  height: 3px;
  background: #000;
}

.gallery-title {
  margin: 60px auto;
}

.gallery-title::after {
  width: 60px;
}

.gallery-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.gallery-item {
  width: 45%;
  max-width: 320px;
}

.gallery-img {
  width: 100%;
  border-radius: 2px;
  box-shadow: -2px 4px 8px rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 768px) {
  .gallery {
    padding: 0 20px;
    margin: 40px auto;
  }

  .gallery-items {
    gap: 40px;
  }
}

@media only screen and (min-width: 1024px) {
  .gallery {
    margin-bottom: 80px;
  }

  .gallery-title::after {
    width: 70px;
  }

  .gallery-items {
    gap: 70px;
  }
}
