.menu {
  width: 100vw;
  height: 100%;
  margin: auto;
  padding: 12px;
  position: relative;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
}

.menu ul {
  list-style: none;
}

@keyframes pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.basket-container {
  position: relative;
  margin-left: 80px;
  z-index: 250;
  animation: none;
}

.basket-container.animate-pop {
  animation: pop 0.5s ease; /* Apply the animation */
}

.basket-container img {
  width: 25px;
}

.basket-container img:hover {
  transform: scale(1.1);
}

.basket-container .item-count {
  position: absolute;
  top: -4px;
  right: -8px;
  color: red;
}

.nav-link,
.active-style {
  text-decoration: none;
  color: #000;
  font-weight: 500;
  display: flex;
  align-items: flex-end;
  gap: 10px;
  font-size: 15px;
}

.nav-link img,
.active-style img {
  width: 24px;
  margin-bottom: 3px;
}

.nav-link:hover,
.active-style {
  font-weight: 700;
  text-shadow: -2px 4px 8px rgba(0, 0, 0, 0.1);
}

.active-style .nav-item span::after {
  content: "";
  display: block;
  width: 80%;
  height: 1.8px;
  background-color: #048808;
  margin: auto;
  border-radius: 50px;
}

.mobile-menu {
  display: none;
}

.mobile-menu.open {
  width: 50%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 60px;
  position: absolute;
  top: 56px;
  right: 0;
  background-color: white;
  padding: 60px 100px;
  border-bottom: 1px solid #000;
  z-index: 800;
}

.mobile-menu-toggle {
  cursor: pointer;
}

.mobile-menu-toggle.open .bar {
  transform: rotate(-40deg) translate(-5px, 6px);
}

.mobile-menu-toggle.open .bar:nth-child(2) {
  opacity: 0;
}

.mobile-menu-toggle.open .bar:last-child {
  transform: rotate(40deg) translate(-5px, -6px);
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #000;
  margin: 4px 0;
  transition: 0.4s;
}

@media (min-width: 769px) {
  .menu {
    flex-direction: row;
    justify-content: flex-end;
  }

  .menu ul {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    top: 0;
    left: 40px;
    gap: 35px;
    font-size: 17px;
    background-color: transparent;
  }

  .basket-container {
    position: relative;
    margin-right: 120px;
  }

  .mobile-menu-toggle {
    display: none;
  }
}

@media (min-width: 900px) {
  .nav-link,
  .active-style {
    font-size: 18px;
  }

  .nav-link img,
  .active-style img {
    width: 26px;
  }
}

@media (min-width: 1200px) {
  .menu ul {
    padding-left: 60px;
    gap: 60px;
  }

  .basket-container {
    margin-right: 150px;
  }
}
