/* Navbar.css */
.main-navigation {
  position: fixed;
  top: 0;
  height: 55px;
  background-color: #fdfcf7;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  z-index: 2000;
}

@media (min-width: 769px) {
  .main-navigation {
    height: 60px;
  }
}
