.section-title {
  max-width: 300px;
  margin: 40px auto 50px;
  position: relative;
  text-shadow: -2px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: -1;
}

.section-title::after {
  content: "";
  width: 120px;
  height: 2px;
  background-color: #05904c;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -15px;
  z-index: -1;
}

@media (min-width: 400px) {
  .section-title {
    font-size: 28px;
  }
}

@media (min-width: 1200px) {
  .section-title {
    font-size: 32px;
    margin-bottom: 70px;
  }
}
