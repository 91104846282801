.about-company-container {
  padding: 30px 20px;
  line-height: 22px;
}

.company-info {
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
}

@media (min-width: 400px) {
  .about-company-container {
    line-height: 24px;
  }
}

@media (min-width: 1200px) {
  .about-company-container {
    padding: 60px 0;
  }

  .company-info {
    gap: 65px;
  }
}
