.company-item {
  max-width: 280px;
  text-align: center;
  border: 3px solid #eee;
  border-radius: 8px;
}

.about-company-subtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
  height: 60px;
  margin-bottom: 15px;
  border-bottom: 3px solid #eee;
}

.about-company-subtitle img {
  width: 65px;
  padding: 0 10px;
  border-right: 3px solid #eee;
}

.about-company-subtitle h3 {
  color: #05904c;
}

.company-item p {
  font-weight: 500;
  font-size: 17px;
  text-shadow: -2px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

@media (min-width: 400px) {
  .company-item {
    max-width: 340px;
  }

  .about-company-subtitle h3 {
    font-size: 22px;
  }

  .company-item p {
    font-size: 20px;
    padding: 10px;
  }
}

@media (min-width: 1200px) {
  .about-company-subtitle h3 {
    font-size: 24px;
  }

  .company-item p {
    font-size: 22px;
    line-height: 30px;
  }
}
