.language-selection {
  position: absolute;
  z-index: 300;
}

.language-selection ul {
  list-style: none;
  display: flex;
  gap: 15px;
  margin-left: 10px;
  margin-top: 5px;
}

.language-selection li {
  cursor: pointer;
}

.language-selection img {
  width: 20px;
  opacity: 0.7;
}

.language-selection img:hover,
.language-selection img.active {
  opacity: 1;
  transform: scale(1.1);
  box-shadow: -2px 4px 8px rgba(0, 0, 0, 0.1);
}

@media (min-width: 769px) {
  .language-selection {
    top: 5px;
    right: 20px;
  }
}
