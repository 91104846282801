#hero {
  background-color: #000;
  display: flex;
  flex-direction: column;
  gap: 25px;
  color: #fff;
  padding: 120px 5px 25px;
  text-align: center;
}

#hero p {
  font-size: 15px;
}

.hero-intro {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
}

.hero-intro .logo {
  max-width: 180px;
}

.hero-intro h1 {
  color: #0bc012;
}

.order-info {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* .hero-delivery-info {
  padding: 0 5px;
} */

@media (min-width: 400px) {
  #hero p {
    font-size: 16px;
  }

  .hero-intro {
    gap: 35px;
  }

  .hero-intro h1 {
    font-size: 26px;
  }

  .hero-delivery-info {
    padding: 8px;
  }
}

@media (min-width: 600px) {
  #hero {
    gap: 30px;
  }

  #hero p {
    font-size: 18px;
  }

  .hero-intro {
    gap: 40px;
  }

  .hero-intro h1 {
    font-size: 30px;
  }
}

@media (min-width: 800px) {
  #hero {
    padding-top: 150px;
  }

  #hero p {
    font-size: 20px;
  }

  .hero-intro {
    gap: 45px;
  }

  .hero-intro h1 {
    font-size: 36px;
  }
}

@media (min-width: 1000px) {
  #hero p {
    font-size: 24px;
  }

  .hero-intro .logo {
    max-width: 200px;
  }

  .hero-intro h1 {
    font-size: 40px;
  }
}
