* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Josefin Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  border: 1px solid #000;
}

/* Customize the scrollbar */
body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-thumb {
  background-color: #484444;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

body::-webkit-scrollbar-track {
  background-color: #fff;
}
