.footer-container {
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 20px 5px;
  margin-top: 40px;
}

.footer-container a {
  color: #fff;
  text-decoration: none;
}

.footer-container a:hover {
  color: #bdbdbd;
  text-decoration: underline;
}

.logo-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 30px 0;
}

.logo-section img {
  width: 120px;
}

.logo-section h3 {
  font-size: 20px;
}

.logo-section h3 .brand-dot {
  display: none;
}

.info-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 25px;
  column-gap: 60px;
  margin: 0 20px;
  margin-bottom: 50px;
}

.info-section h4 {
  margin: 15px auto;
  color: #05904c;
  font-size: 18px;
}

.info-section > div p {
  margin: 20px 5px;
}

.info-section > div p .label {
  margin-right: 8px;
}

.address h4 {
  margin-bottom: -6px;
}

.address p {
  line-height: 25px;
}

.address span {
  display: block;
}

.credits h4 {
  margin: 15px 20px;
  color: #05904c;
}

.credits a {
  display: block;
  font-size: 15px;
}

.credits .copyright {
  margin-top: 30px;
  font-size: 14px;
}

/* Adjust styles for mobile and desktop as needed */
@media (min-width: 768px) {
  .logo-section {
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    margin-bottom: 40px;
    padding-right: 100px;
  }

  .logo-section h3 .brand-dot {
    display: inline-block;
    font-size: 55px;
    margin: 0 10px;
    line-height: 10px;
    position: relative;
  }

  .logo-section h3 .brand-dot::after {
    content: "";
    position: absolute;
    top: 15px;
    left: 28px;
    transform: translateY(-50%);
    height: 1px;
    width: 120px; /* Adjust as needed */
    background-color: white;
  }

  .info-section {
    column-gap: 120px;
    row-gap: 40px;
    margin-bottom: 70px;
  }
}

@media (min-width: 1100px) {
  .info-section {
    padding-right: 60px;
  }
}
